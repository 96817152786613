<template>
	<div id="cross-reference" @click.right.prevent>
		<v-card height="100%">
			<div class="py-5 px-5">
				<div class="d-flex justify-end my-5 pr-10 print-btn">
					<button v-if="!hidePrint" type="button" class="form-btn ml-1 mr-2" @click="print()">
						Print
					</button>
				</div>
				<RichText :interactive="false" :textContent="content" @productSelected="productSelected" />
			</div>
		</v-card>
		<div class="watermark"><img class="logo-svg" src="@/assets/svg/Chemformation_logo_Horz_K.svg" /></div>

		<ResultsModal
			v-if="showDetailedView"
			:productId="productId"
			:vendorLogo="null"
			@close="showDetailedView = false"
		/>
	</div>
</template>

<script>
import RichText from "@/components/RichText.vue";
import ResultsModal from "@/components/ProductSearch/ResultsModal.vue";
export default {
	name: "CrossReference",
	components: {
		RichText,
		ResultsModal,
	},
	data() {
		return {
			content: "",
			isEdit: false,
			crossReferenceId: null,
			hidePrint: false,
			productId: null,
			showDetailedView: false,
		};
	},
	methods: {
		async init() {
			this.crossReferenceId = this.$route.query.crossReferenceId;
			const res = await this.$http.get(`/api/cross-reference/get`, {
				params: { crossReferenceId: this.$route.query.crossReferenceId },
			});

			if (res.data && res.data.response) {
				this.content = res.data.response.content;
			}
		},
		print() {
			this.hidePrint = true;

			setTimeout(() => {
				window.print();

				setTimeout(() => {
					this.hidePrint = false;
				}, 1000);
			}, 0);
		},
		productSelected(productId) {
			this.productId = Number(productId);
			this.showDetailedView = true;
			console.log("product selected", productId);
		},
	},
	async mounted() {
		const appContent = document.querySelector(".app-content");
		if (appContent) {
			appContent.style.paddingTop = "0px";
		}
		await this.init();
	},
};
</script>

<style lang="scss">
@media print {
	@page {
		size: a3 portrait;
	}

	.watermark {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(315deg);
		font-size: 100px;
		color: rgba(0, 0, 0, 0.1);
		-webkit-print-color-adjust: exact;
		opacity: 0.05;
		pointer-events: none;
	}

	.logo-svg {
		height: 80px;
	}
}

@media screen {
	.watermark {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(315deg);
		font-size: 100px;
		color: rgba(0, 0, 0, 0.1);
		-webkit-print-color-adjust: exact;
		opacity: 0.05;
		pointer-events: none;
	}

	.logo-svg {
		height: 100px;
	}
}

.cross-ref {
	min-width: 980px;
}
</style>

<style lang="scss" scoped>
.print-btn {
	position: absolute;
	z-index: 9999;
	width: 100%;
}
.editor,
.editor__content {
	min-height: 90vh;
}

#cross-reference {
	position: relative; // Set the position property to relative
	// Prevent Copy-Paste
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
</style>
